import LanguageModal from './LanguageModal'
import SystemVersionModal from './SystemVersionModal'
import ResetPasswordModal from './ResetPasswordModal'
import RentalConfirmModal from './RentalConfirmModal'
import ConfirmLoginModal from './ConfirmLoginModal'
import ForgotPassowrdModal from './ForgotPassowrdModal'
import ConfirmModal from '@components/ui/Modal/ConfirmModal'
import OrganizationListModal from './OrganizationListModal'
import UpdateConfirmModal from '@components/ui/Modal/UpdateConfirmModal'
import PageNoPermissionModal from './PageNoPermissionModal'
import AnnouncementCoeffiDetailModal from './AnnouncementCoeffiDetailModal'

interface IModal {
  LanguageModal: typeof LanguageModal
  SystemVersionModal: typeof SystemVersionModal
  ResetPasswordModal: typeof ResetPasswordModal
  ForgotPassowrdModal: typeof ForgotPassowrdModal
  RentalConfirmModal: typeof RentalConfirmModal
  ConfirmLoginModal: typeof ConfirmLoginModal
  ConfirmModal: typeof ConfirmModal
  OrganizationListModal: typeof OrganizationListModal
  UpdateConfirmModal: typeof UpdateConfirmModal
  PageNoPermissionModal: typeof PageNoPermissionModal
  // 係數大表
  AnnouncementCoeffiDetailModal: typeof AnnouncementCoeffiDetailModal
}

const Modals: IModal = {
  OrganizationListModal,
  ConfirmModal,
  ConfirmLoginModal,
  LanguageModal,
  RentalConfirmModal,
  ResetPasswordModal,
  SystemVersionModal,
  ForgotPassowrdModal,
  UpdateConfirmModal,
  PageNoPermissionModal,
  AnnouncementCoeffiDetailModal,
}

export default Modals
