import Button from '@components/ui/Button'
import { useExportFile } from '@lib/file'
import { ButtonProps } from 'antd/lib/button'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IExportButtonProps extends ButtonProps {
  query?: Record<string, any>
  apiUrl: string
  fileName?: string
  isNotSupportAdmin?: boolean
}

const Export = React.memo<IExportButtonProps>(props => {
  const { t } = useTranslation()
  const {
    query,
    fileName,
    apiUrl,
    isNotSupportAdmin = false,
    ...others
  } = props

  const { exportFile } = useExportFile({
    filename: fileName || '',
    apiUrl,
    isNotSupportAdmin,
  })

  return (
    <Button
      color="grey"
      onClick={() => exportFile(query)}
      className="ml-16"
      {...others}>
      {t('common:exportCurrentFile')}
    </Button>
  )
})

export default React.memo<Partial<IExportButtonProps>>(props => {
  if (props.fileName) {
    return <Export {...(props as any)} />
  } else {
    return null
  }
})
